import React from 'react';
import { Placeholder, VisitorIdentification } from '@sitecore-jss/sitecore-jss-react';
import { NavLink } from 'react-router-dom';
import deepEqual from 'deep-equal';
import Helmet from 'react-helmet';
import CookieConsent from 'react-cookie-consent';
import ScrollToTop from './helpers/scroll-to-top';
import './assets/styles/pagestyles.scss';

/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const Layout = ({ route }) => {

  let googleScript;
  let googleInit;
  let googleAdsScript;
  let googleAdsInit;

  if (route.fields && route.fields.googleSettings && route.fields.googleSettings.fields) {
    if (route.fields.googleSettings.fields.trackingId && route.fields.googleSettings.fields.trackingId.value) {
      googleScript = googleScriptLink(route.fields.googleSettings.fields.trackingId.value);
      googleInit =
        createGoogleScript(route.fields.googleSettings.fields.trackingId.value);
    }
    if (route.fields.googleSettings.fields.adsId && route.fields.googleSettings.fields.adsId.value) {
      googleAdsScript = googleScriptLink(route.fields.googleSettings.fields.adsId.value);
      googleAdsInit =
        createGoogleScript(route.fields.googleSettings.fields.adsId.value);
    }
  }

  return <React.Fragment>
    {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
    <Helmet
          title={(route.fields && route.fields.pageTitle && route.fields.pageTitle.value) || 'Include Agency'}
          meta={[
              {name:'keywords', content: (route.fields && route.fields.keywords && route.fields.keywords.value) || 'include, include agency, sitecore, .net, development, developers, consultancy'},
              {name: 'description', content: (route.fields && route.fields.pageDescription && route.fields.pageDescription.value) || 'Include Agency is a specialist Sitecore and .NET web development agency based in Denmark with points of presence in Dubai and Germany'},
              {name:'robots', content: (route.fields && route.fields.robots && route.fields.robots.value) || ''},
              {property:'og:image', content: (route.fields && route.fields.thumbnail && route.fields.thumbnail.value && route.fields.thumbnail.value.src) || ''}
          ]}
      >
        {googleScript}
        {googleInit}
        {googleAdsScript}
        {googleAdsInit}
        </Helmet>
    {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
    <VisitorIdentification />

    {/* root placeholder for the app, which we add components to using route data */}
    <ScrollToTop />
    <div className="main">
      <Placeholder name="jss-top" rendering={route} />
      <div className={(route.fields && route.fields.pageClassName && route.fields.pageClassName.value) || ''}>
        <Placeholder name="jss-main" rendering={route} />
      </div>
      <CookieConsent
          location="bottom"
          buttonText="OK"
          disableStyles={true}
          cookieName="user-cookies-accepted"
          containerClasses="cookie-banner-container"
          buttonClasses="cookie-btn"
          expires={150}
      >
      We use cookies to help us offer you the best online experience. By continuing to use our website and/or
      clicking OK, you agree to our use of cookies in accordance with our
      <NavLink className="link-reset" to="/privacy#cookies"> Privacy Policy</NavLink>.
      </CookieConsent>
    </div>
  </React.Fragment>
}

// We don't want to re-render `Layout` when route is changed but layout data is not loaded
// Layout will be re-rendered only when layout data is changed
const propsAreEqual = (prevProps, nextProps) => {
  if (deepEqual(prevProps.route, nextProps.route)) return true;

  return false;
};

export default React.memo(Layout, propsAreEqual);
function createGoogleScript(googleId) {
  return <script>{`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${googleId}');
          `}
  </script>;
}

function googleScriptLink(googleId) {
  return <script async src={`https://www.googletagmanager.com/gtag/js?id=${googleId}`} />;
}

